import { Card, Typography, message } from "antd";
import { Form, Button, Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { submitData } from "../../services/RealEstateFolder.service";
import LeftCol from "./LeftCol";
import RightCol from "./RightCol";
import { useUser } from "../../context/UserContext";
import {
  Condition,
  FolderDetails,
  REFolderStatus,
} from "../../types/REFolder.interface";
import { MAIN_COLOR } from "../../utils";
import { checkApiError } from "../../apiCheckError";

const { Title } = Typography;

const StyledCard = styled(Card)`
  width: 85%;
  margin: 0 auto;
  border: 1px solid ${MAIN_COLOR};

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const CenteredTitle = styled(Title)`
  text-align: center;
  margin-bottom: 16px;
`;

const ButtonWrapper = styled.div`
  justify-content: flex-end;
  right: 20px;

  @media (max-width: 768px) {
    position: static;
    margin-top: 20px;
    text-align: center;
  }
`;

const SimulationText = styled.div`
  font-size: 18px;
  padding: 8px;
  border-radius: 8px;

  .title {
    font-weight: bold;
    margin-bottom: 16px;
    font-size: 24px;
  }

  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
  }

  .row-mr {
    padding-left: 8px;
    padding-right: 8px;
    font-size: 8px;
  }

  .detail1 {
    font-size: 18px;
  }

  .detail2 {
    font-size: 16px;
  }

  .price1 {
    font-size: 20px;
    font-weight: bold;
    text-align: right;
    flex: 1;
  }

  .price2 {
    font-size: 16px;
    text-align: right;
    flex: 1;
  }
`;

const VerticalDivider = styled.div`
  border-left: 2px solid #d9d9d9;
  height: 100%;
  margin: 0 16px;
`;

const SimulatorComponent: React.FC = () => {
  const navigate = useNavigate();
  const methods = useForm();
  const { control, watch, getValues } = methods;
  const { user } = useUser();

  const [loanAmount, setLoanAmount] = useState(330000);
  const [monthlyPayment, setMonthlyPayment] = useState(2493);
  const [monthlyInsurance, setMonthlyInsurance] = useState(94);
  const [totalCreditCost, setTotalCreditCost] = useState(0);
  const [notaryCost, setNotaryCost] = useState(0);
  const [totalInsuranceCost, setTotalInsuranceCost] = useState(0);

  const propertyPrice = parseFloat(watch("propertyPrice") || "0");
  const reeadyCosts = 750;
  const bankCosts = 750;
  const notaryRate = 8 / 100;
  const renovationBudget = parseFloat(watch("renovationBudget") || "0");
  const loanDuration = parseFloat(watch("loanDuration") || "3") * 12;
  const desiredContribution = parseFloat(watch("desiredContribution") || "0");
  const interestRate = parseFloat(watch("interestRate") || "3.5") / 100;
  const insuranceRate = parseFloat(watch("insuranceRate") || "0.2") / 100;

  useEffect(() => {
    setNotaryCost(propertyPrice * notaryRate);

    const totalCost =
      propertyPrice +
      renovationBudget +
      reeadyCosts +
      bankCosts +
      propertyPrice * notaryRate;
    const loanAmount = totalCost - desiredContribution;
    const monthlyInterestRate = interestRate / 12;
    const numberOfPayments = loanDuration;

    const monthlyPayment =
      (loanAmount * monthlyInterestRate) /
      (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments));
    const annualInsurance = loanAmount * insuranceRate;
    const monthlyInsurance = annualInsurance / 12;

    setLoanAmount(Math.ceil(loanAmount));
    setMonthlyPayment(Math.ceil(monthlyPayment));
    setMonthlyInsurance(Math.ceil(monthlyInsurance));
    const totalInsuranceCost = Math.ceil(monthlyInsurance * numberOfPayments);
    const totalCreditCost = Math.ceil(
      monthlyPayment * numberOfPayments - loanAmount + totalInsuranceCost,
    );

    setTotalCreditCost(totalCreditCost);
    setTotalInsuranceCost(totalInsuranceCost);
  }, [
    propertyPrice,
    renovationBudget,
    loanDuration,
    desiredContribution,
    interestRate,
    insuranceRate,
    notaryRate,
  ]);

  const handleButtonClick = async () => {
    const values = getValues();
    const data: Partial<FolderDetails> = {
      propertyType: values.propertyType,
      condition: Condition.Old,
      propertyPrice: parseFloat(values.propertyPrice || 0),
      renovationBudget: parseFloat(values.renovationBudget || 0),
      address: `${values.address.num} ${values.address.street}, ${values.address.zip_code} ${values.address.city}`,
      notaryFeesPercentage: 8,
      loanDuration: parseInt(values.loanDuration),
      desiredContribution: parseFloat(values.desiredContribution || 0),
      status: REFolderStatus.Completion,
      files: [],
    };

    if (!user) {
      localStorage.setItem("savedFormData", JSON.stringify(data));
      message.info(
        "Pour continuer la création de votre dossier, merci de vous connecter ou de créer un compte",
      );
      navigate("/authentification");
      return;
    }

    const result = await submitData(data);
    checkApiError(result.respStatus);
    navigate(`/mes-dossiers/${result.respData.id}`);
  };

  return (
    <FormProvider {...methods}>
      <StyledCard
        title={<CenteredTitle level={3}>Simuler mon prêt</CenteredTitle>}
        bordered={false}
      >
        <Form layout="vertical">
          <Row gutter={[8, 8]}>
            <Col xs={24} md={11}>
              <LeftCol control={control} />
            </Col>
            <VerticalDivider />
            <Col xs={24} md={11}>
              <RightCol control={control} />
              <SimulationText>
                <div className="title">Votre simulation :</div>
                <div className="row">
                  <div className="detail1">Montant de votre prêt :</div>
                  <div className="price1">{loanAmount.toLocaleString()} €</div>
                </div>
                <div className="row row-mr">
                  <div className="detail2">dont frais de notaires : </div>
                  <div className="price2">{notaryCost.toLocaleString()} €</div>
                </div>
                <div className="row row-mr">
                  <div className="detail2">frais reeady : </div>
                  <div className="price2">{reeadyCosts.toLocaleString()} €</div>
                </div>
                <div className="row row-mr">
                  <div className="detail2">frais de banque : </div>
                  <div className="price2">{bankCosts.toLocaleString()} €</div>
                </div>
                <div className="row">
                  <div className="detail1">Votre mensualité :</div>
                  <div className="price1">
                    {(monthlyPayment + monthlyInsurance).toLocaleString()} € /
                    mois
                  </div>
                </div>
                <div className="row row-mr">
                  <div className="detail2">dont assurance :</div>
                  <div className="price2">
                    {monthlyInsurance.toLocaleString()}€ / mois
                  </div>
                </div>
                <div className="row">
                  <div className="detail1">Coût total du crédit :</div>
                  <div className="price1">
                    {totalCreditCost.toLocaleString()} €
                  </div>
                </div>
                <div className="row row-mr">
                  <div className="detail2">dont assurance :</div>
                  <div className="price2">
                    {totalInsuranceCost.toLocaleString()} €
                  </div>
                </div>
              </SimulationText>
            </Col>
          </Row>
        </Form>
        <ButtonWrapper>
          <Button type="primary" onClick={handleButtonClick}>
            Créer mon dossier
          </Button>
        </ButtonWrapper>
      </StyledCard>
    </FormProvider>
  );
};

export default SimulatorComponent;
