import React from "react";
import {
  UserOutlined,
  BarChartOutlined,
  SettingOutlined,
  LogoutOutlined,
  SolutionOutlined,
  FolderOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import logo from "../../../assets/reeady.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { logout } from "../../../services/users.service";
import { HOOVER_COLOR, REAL_BACKGROUND_COLOR } from "../../../utils";
import { checkApiError } from "../../../apiCheckError";

const { Sider } = Layout;

const SidebarContainer = styled(Sider)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${REAL_BACKGROUND_COLOR} !important;
  border-right: 1px solid ${HOOVER_COLOR};

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .ant-menu-dark.ant-menu-inline {
    background-color: ${REAL_BACKGROUND_COLOR} !important;
  }
`;

const LogoContainer = styled.div`
  padding: 16px;
  text-align: center;
`;

const StyledMenuItem = styled(Menu.Item)<{ $selected: boolean }>`
  ${(props) =>
    props.$selected &&
    `
    .ant-menu-title-content {
      color: black !important;
    }
  `}
`;

const items = [
  { key: "/banque", icon: <SolutionOutlined />, label: "Mes enchères" },
  {
    key: "/banque/mes-dossiers",
    icon: <FolderOutlined />,
    label: "Mes dossiers en direct",
  },
  {
    key: "/banque/mes-dossiers",
    icon: <FolderOutlined />,
    label: "Mes dossiers Reeady",
  },
  {
    key: "/banque/statistiques",
    icon: <BarChartOutlined />,
    label: "Statistiques",
  },
  { key: "/banque/mon-profil", icon: <UserOutlined />, label: "Profil" },
  { key: "/banque/parametres", icon: <SettingOutlined />, label: "Paramètres" },
];

const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleMenuClick = (e: any) => {
    navigate(e.key);
  };

  const handleLogout = async () => {
    checkApiError((await logout()).respStatus);
    navigate("/banque/authentification");
  };

  const selectedKey =
    items
      .map((item) => item.key)
      .reverse()
      .find((key) => location.pathname.startsWith(key)) || "/banque";

  return (
    <SidebarContainer breakpoint="lg" collapsedWidth="0">
      <div>
        <LogoContainer>
          <Link to="/banque">
            <img src={logo} alt="Logo" style={{ height: "40px" }} />
          </Link>
        </LogoContainer>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[selectedKey]}
          onClick={handleMenuClick}
        >
          {items.map((item) => (
            <StyledMenuItem key={item.key} $selected={item.key === selectedKey}>
              {item.icon}
              <span>{item.label}</span>
            </StyledMenuItem>
          ))}
        </Menu>
      </div>
      <Menu
        theme="dark"
        mode="inline"
        onClick={handleLogout}
        style={{ width: "100%" }}
      >
        <Menu.Item key="/logout" icon={<LogoutOutlined />}>
          Déconnexion
        </Menu.Item>
      </Menu>
    </SidebarContainer>
  );
};

export default Sidebar;
