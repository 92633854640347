import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "./auth.hook";
import Navbar from "../component/navbar/Navbar";
import { useEffect, useState } from "react";
import { Spin } from "antd";
import styled from "styled-components";

const CenteredSpinner = styled(Spin)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

const ProtectedRoute = () => {
  const location = useLocation();
  const { isLoggedIn, loading, checkValidationCode } = useAuth();
  const validationCode = new URLSearchParams(location.search).get(
    "validationCode",
  );
  const [checkComplete, setCheckComplete] = useState<boolean>(false);

  useEffect(() => {
    const validateCode = async () => {
      if (validationCode && !isLoggedIn) {
        await checkValidationCode(validationCode);
      }
      setCheckComplete(true);
    };
    validateCode();
  }, [validationCode, checkValidationCode, isLoggedIn]);

  if (loading || !checkComplete) {
    return <CenteredSpinner size="large" />;
  }

  return isLoggedIn ? (
    <>
      <Navbar />
      <Outlet />
    </>
  ) : (
    <Navigate to="/authentification" state={{ from: location }} replace />
  );
};

export default ProtectedRoute;
